const theme: { [key: string]: string } = {
    primary: '#00809F',
    secondary: '#1297B7',
    warning: '#E3B004',
    error: '#ff4d4f',

    grey: '#535353',
    'grey-1': '#AEADA8',
    'light-grey': '#f2f2f2',

    cyan: '#C1D9DF',
    'cyan-1': '#BBDAE0',
    'cyan-2': '#0DA5AB',
    'cyan-3': '#0DA5AB',
    'cyan-4': '#4fd4ca',
    'cyan-5': '#6FB4B7',

    orange: '#F29423',
    white: '#FFFFFF',
    tip: '#B9B9B9',
};
export default theme;
