export const APP_PREFIX = '/app';
export const IFTTT_PREFIX = '/ifttt';
export const STORE_PREFIX = '/store';
export const FULLPAGE_PREFIX = '/';

export const ACCOUNT = {
    TENANT_MGM: `${APP_PREFIX}/organization-management`,
    PERMISSION_MGM: `${APP_PREFIX}/role-management`,
    TENANT_SERVICE_MGM: `${APP_PREFIX}/tenant-service-management`,
    ACCOUNT_MGM: `${APP_PREFIX}/user-management`,
    STAFFING_MGM: `${APP_PREFIX}/staffing-management`,
    VENDOR_MGM: `${APP_PREFIX}/vendor-management`,
    SITE_PERMISSION_MGM: `${APP_PREFIX}/site-permission-management`,
};

export const DEVICE = {
    DEVICE_TYPE_MGM: `${APP_PREFIX}/device-type`,
    DEVICE_MODEL_MGM: `${APP_PREFIX}/device-model`,
    DEVICE_CONFIG: `${APP_PREFIX}/device-config`,
    GROUPS_DEVICE_MGM: `${APP_PREFIX}/groups-device-management`,
    DEVICE_MGM: `${APP_PREFIX}/device-management`,
    MOBILE_DEVICE_MGM: `${APP_PREFIX}/mobile-device-management`,
    MOBILE_DEVICE_MGM_ADMIN: `${APP_PREFIX}/mobile-device-management-admin`,
};

export const APPLICATION = {
    DEVICE_MAP: `${APP_PREFIX}/device-map`,
    IFTTT: `${IFTTT_PREFIX}`,
    AI_STORE: `${STORE_PREFIX}`,
    DEV_CENTER: `http://10.31.101.97:8000`,
};

export const AIR_PROGRAM = {
    // FIRMWARE_MGM: `${APP_PREFIX}/firmware-management`,
    FIRMWARE_MGM: `${APP_PREFIX}/ota-lite`,
    AIR_OTA_MGM: `${APP_PREFIX}/air-ota-management`,
};

export const TEMPLATE = {
    TRIGGER_TEMPLATE: `${APP_PREFIX}/trigger-template`,
    TRIGGER_ACTION: `${APP_PREFIX}/trigger-action`,
    TRIGGER_RULE: `${APP_PREFIX}/trigger-rule`,
};

export const STORE_MGM = {
    PRODUCT_ATTRIBUTES: `${APP_PREFIX}/product-attributes`,
    PRODUCT_MGM: `${APP_PREFIX}/product-management`,
    FOTA_CAMPAIGN_MGM: `${APP_PREFIX}/fota-campaign-management`,
};

export const IFTTT = {
    IFTTT_HOME: IFTTT_PREFIX,
};

export const STORE = {
    STORE_HOME: STORE_PREFIX,
    SHOPPING_CART: `${STORE_PREFIX}/shopping-cart`,
    PROGRAM_DETAIL: `${STORE_PREFIX}/program/:id`,
    STORE_BINDING: `${STORE_PREFIX}/binding`,
    BINDING_DETAIL: `${STORE_PREFIX}/binding/detail/:id`,
    BINDING_RECORD: `${STORE_PREFIX}/binding/record/:id`,
    PROGRAM: `${STORE_PREFIX}/program`,
};

export const SERVICE_MGM = {
    LICENSE_SITE: '',
    LICENSE_TENANT: '',
    ARGO_CD:
        'https://argocd.biotrd.net/login?return_url=https%3A%2F%2Fargocd.biotrd.net%2Fapplications',
    LOG_SYSTEM: 'https://kibana.biotrd.net/app/home#/',
    JAEGER: 'https://jaeger.biotrd.net/search',
};
const PATH = {
    LOGIN: '/',
    OVERVIEW: `${APP_PREFIX}/overview`,
    EXPLANATION: `${APP_PREFIX}/explanation`,
    ...ACCOUNT,
    ...DEVICE,
    ...APPLICATION,
    ...AIR_PROGRAM,
    ...STORE_MGM,
    ...TEMPLATE,
    ...IFTTT,
    ...STORE,
    ...SERVICE_MGM,
};

export const keyMap = Object.keys(PATH).reduce(
    (prv, cur) => ({ ...prv, [PATH[cur]]: cur }),
    {}
);

export default PATH;
