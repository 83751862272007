import React, { useState, useEffect } from 'react';
import {
    GlobalContextType,
    FitConfig,
    DeviceAuthList,
} from 'types/GlobalContextType';
import { getUserConfig } from 'api/userConfigApi';
import { NotificationProps } from 'api/notificationApi';

export const GlobalContext = React.createContext<GlobalContextType>(
    {} as GlobalContextType
);

type AppcontextProps = {};
const AppContextProvider: React.FC<AppcontextProps> = ({ children }) => {
    // https://kentcdodds.com/blog/should-i-usestate-or-usereducer
    // 設定GlobalContext也可以用useReducer
    const [permissionCtx, setPermissionCtx] = useState({});
    const [closableMenu, setClosableMenu] = useState(false);
    const [visibleMenu, setVisibleMenu] = useState(false); // closableMenu = true才會有作用
    const [lang, setLang] = useState(
        localStorage.getItem('language') || navigator.language
    );
    const [configData, setConfigData] = useState<FitConfig>({});
    const [groupTenantMapping, setGroupTenantMapping] = useState({});
    const [notificationList, setNotificationList] = useState<
        NotificationProps[]
    >([]);
    const [deviceAuthList, setDeviceAuthList] = useState<DeviceAuthList>();

    useEffect(() => {
        localStorage.setItem('language', lang);
    }, [lang]);

    const changeClosable = (bool: boolean) => {
        if (!bool) {
            setVisibleMenu(false);
        }
        setClosableMenu(bool);
    };
    return (
        <GlobalContext.Provider
            value={{
                permissionCtx,
                setPermissionCtx,
                closableMenu,
                setClosableMenu: changeClosable,
                visibleMenu,
                setVisibleMenu,
                lang,
                setLang,
                configData,
                setConfigData,
                groupTenantMapping,
                setGroupTenantMapping,
                notificationList,
                setNotificationList,
                deviceAuthList,
                setDeviceAuthList,
            }}
        >
            {children}
        </GlobalContext.Provider>
    );
};

export default AppContextProvider;
