import { css, createGlobalStyle } from 'styled-components';
import theme from './theme';

const spaces = [
    0, 1, 2, 3, 4, 5, 8, 10, 12, 15, 16, 18, 20, 24, 30, 32, 36, 40, 42, 50, 72,
];

const base = css`
    ${Object.keys(theme).map(
        (key: string) => css`
            .text--${key} {
                color: ${theme[key]};
            }

            .bg--${key} {
                background: ${theme[key]};
            }

            .bt-1--${key} {
                border-top: 1px solid ${theme[key]};
            }

            .bt-2--${key} {
                border-top: 2px solid ${theme[key]};
            }

            .ball-1--${key} {
                border: 1px solid ${theme[key]};
            }
        `
    )}

    .text--gray {
        color: #aeada8;
    }

    .text--red {
        color: red !important;
    }

    .text--ellipsis {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .text--dark {
        color: #535353;
    }

    .text--white {
        color: white;
    }

    .text--500 {
        font-weight: 500;
    }

    .text--left {
        text-align: left;
    }

    .text--right {
        text-align: right;
    }

    .text--center {
        text-align: center;
    }

    .text--justify {
        text-align: justify;
    }

    .text--xs {
        font-size: 10px;
    }

    .text--sm {
        font-size: 12px;
    }

    .text--m {
        font-size: 14px;
    }

    .text--lg {
        font-size: 16px;
    }

    .text--xl {
        font-size: 24px !important;
    }

    .text--xxl {
        font-size: 32px;
    }

    .shape--circle {
        border-radius: 32px;
    }
`;

const layout = css`
    ${spaces.map(
        (x) => css`
            .text-${x} {
                font-size: ${x}px !important;
            }
            .mt-${x} {
                margin-top: ${x}px !important;
            }

            .mb-${x} {
                margin-bottom: ${x}px !important;
            }

            .ml-${x} {
                margin-left: ${x}px !important;
            }

            .mr-${x} {
                margin-right: ${x}px !important;
            }

            .pt-${x} {
                padding-top: ${x}px !important;
            }

            .pb-${x} {
                padding-bottom: ${x}px !important;
            }

            .pl-${x} {
                padding-left: ${x}px !important;
            }

            .pr-${x} {
                padding-right: ${x}px !important;
            }

            .-mt-${x} {
                margin-top: -${x}px !important;
            }

            .-mb-${x} {
                margin-bottom: -${x}px !important;
            }

            .-ml-${x} {
                margin-left: -${x}px !important;
            }

            .-mr-${x} {
                margin-right: -${x}px !important;
            }

            .-pt-${x} {
                padding-top: -${x}px !important;
            }

            .-pb-${x} {
                padding-bottom: -${x}px !important;
            }

            .-pl-${x} {
                padding-left: -${x}px !important;
            }

            .-pr-${x} {
                padding-right: -${x}px !important;
            }

            .padding-${x} {
                padding: ${x}px;
            }
            .lineHeight-${x} {
                line-height: ${x}px;
            }
            .height-${x} {
                height: ${x}px;
            }

            .width-${x} {
                width: ${x}px;
            }
        `
    )};

    .width--full {
        width: 100%;
    }

    .d-inline-block {
        display: inline-block;
    }

    .flex-1 {
        flex: 1;
    }

    .pull--right {
        float: right;
    }

    .d-block {
        display: block;
    }

    .d-flex {
        display: flex;

        .is--inline {
            display: inline-flex;
        }

        &.is--column {
            flex-direction: column;
        }
        &.is--row {
            flex-direction: row;
        }

        &.is--space-between {
            justify-content: space-between;
        }

        &.v--center {
            align-items: center;
        }

        &.v--bottom {
            align-items: flex-end;
        }

        &.h--center {
            justify-content: center;
        }

        &.is--center {
            align-items: center;
            justify-content: center;
        }

        &.align-items--start {
            align-items: flex-start;
        }
    }

    .cursor--pointer {
        cursor: pointer;
    }
`;

const component = css`
    .modal__info {
        animation-duration: 0s !important;
        transition: none !important;

        .ant-modal-content {
            border-radius: 12px;
        }

        .ant-modal-body {
            padding-top: 20px;
            padding-bottom: 0;
        }

        .anticon {
            font-size: 90px;
        }

        &.is--success {
            .anticon {
                color: ${(p) => p.theme['cyan-1']};
            }
        }

        &.is--warning {
            .anticon {
                color: ${(p) => p.theme.warning};
            }
        }

        &.is--error {
        }
    }

    .calendar-event__popover {
        width: 400px;

        .ant-popover-inner,
        .ant-popover-inner-content {
            border-radius: 12px;
            /* box-shadow: 0px 3px 6px #0000004d; */
        }

        .ant-popover-inner-content {
            padding: 6px 32px 16px;
        }

        .ant-popover-arrow {
            display: none;
        }
    }

    .ant-picker__popup {
        .ant-picker-now {
            display: none;
        }
    }

    .ant-modal__footer--border-none {
        .ant-modal-footer {
            border-top: none;
        }
    }

    .ant-table__thead--grey {
        thead > tr > th {
            background: #f2f2f2;
        }
    }

    // 必填*放在後面
    .ant-form-item-label > label {
        flex-direction: row-reverse;
    }

    .ant-form-item-label > label.ant-form-item-required::before {
        margin-right: 0px;
        margin-left: 4px;
    }

    .ant-modal-mask,
    .ant-modal-wrap {
        z-index: 1002;
    }

    .ant-input-search {
        .ant-input {
            border-right: none;
            border-top-left-radius: 32px;
            border-bottom-left-radius: 32px;
        }
        .ant-input-group
            > .ant-input-group-addon:last-child
            > .ant-input-search-button {
            border-left: none;
            border-radius: 0 32px 32px 0;
        }
    }

    .ant-input-group-addon {
        background: none;
    }

    .ant-checkbox-indeterminate .ant-checkbox-inner::after {
        background-color: #5ac6c9;
    }

    .ant-checkbox-checked .ant-checkbox-inner {
        background-color: #5ac6c9;
        border-color: #5ac6c9;
    }

    .ant-select-single .ant-select-selector .ant-select-selection-item,
    .ant-select-single .ant-select-selector .ant-select-selection-placeholder {
        padding-left: 10px;
    }

    .ant-form-item-no-colon {
        padding-right: 8px;
    }

    .jodit-workplace {
        background: white;
    }

    .ant-select:not(.ant-select-customize-input) .ant-select-selector {
        border-radius: 4px;
    }

    .notification-popup-message {
        margin-right: -24px;
        margin-bottom: 0;
        background-color: #075180;
        width: 510px;
        .ant-notification-notice-message {
            padding-right: 0px;
        }
    }
    textarea.ant-input[disabled] {
        color: ${(p) => p.theme.grey};
    }
`;

const antdOverdies = css`
    .ant-spin-dot-item {
        background: ${(p) => p.theme.primary} !important;
    }
`;

export default createGlobalStyle`
    ${antdOverdies};
    ${base};
    ${layout};
    ${component};
`;
