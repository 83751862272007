export default {
    format: '{reason} 位於第 {line} 行',
    symbols: {
        colon: '冒號', // :
        comma: '逗號', // ,  ،  、
        semicolon: '分號', // ;
        slash: '反斜杠', // /  relevant for comment syntax support
        backslash: '正斜杠', // \  relevant for escaping character
        brackets: {
            round: '圓括號', // ( )
            square: '方括號', // [ ]
            curly: '大括號', // { }
            angle: '尖括號', // < >
        },
        period: '句號', // . Also known as full point, full stop, or dot
        quotes: {
            single: '單引號', // '
            double: '雙引號', // "
            grave: '反引號', // ` used on Javascript ES6 Syntax for String Templates
        },
        space: '空格', //
        ampersand: '符號&', //	&
        asterisk: '符號*', //	*  relevant for some comment sytanx
        at: '符號@', //	@  multiple uses in other coding languages including certain data types
        equals: '符號=', //	=
        hash: '符號#', //	#
        percent: '百分號', //	%
        plus: '加號', //	+
        minus: '減號', //	−
        dash: '破折號', //	−
        hyphen: '連字符', //	−
        tilde: '波浪號', //	~
        underscore: '下劃線', //	_
        bar: '豎線', //	|
    },
    types: {
        key: 'key',
        value: 'value',
        number: 'number',
        string: 'string',
        primitive: 'primitive',
        boolean: 'boolean',
        character: 'character',
        integer: 'integer',
        array: 'array',
        float: 'float',
        // ... Reference: https://en.wikipedia.org/wiki/List_of_data_structures
    },
    invalidToken: {
        tokenSequence: {
            prohibited: "'{firstToken}' 不能位於 '{secondToken}'之後",
            permitted: "'{firstToken}' 只能位於 '{secondToken}'之後",
        },
        termSequence: {
            prohibited: ' {firstTerm} 不能位於{secondTerm} 之後',
            permitted: ' {firstTerm} 只能位於{secondTerm} 之後',
        },
        double: "'{token}' 不能位於另一個 '{token}' 之後",
        useInstead: "'{badToken}' 不被接受. 使用 '{goodToken}' 替代",
        unexpected: "出乎意料的 '{token}' ",
    },
    brace: {
        curly: {
            missingOpen: "無法找到 '{' ",
            missingClose: "無法找到 '}' ",
            cannotWrap: "'{token}' 無法被包含在 '{}' 中",
        },
        square: {
            missingOpen: "無法找到 '[' ",
            missingClose: "無法找到 ']' ",
            cannotWrap: "'{token}' 無法被包含在 '[]' 中",
        },
    },
    string: {
        missingOpen: "無法找到/無效的 前綴 '{quote}' ",
        missingClose: "無法找到/無效的 後綴 '{quote}' ",
        mustBeWrappedByQuotes: '字符串必須用引號括起來',
        nonAlphanumeric: "非數字字符 '{token}' 無法使用外部字符串表示法",
        unexpectedKey: '在字符串位置找到意外鍵',
    },
    key: {
        numberAndLetterMissingQuotes: '用數字和字母包含的開頭必須用引號括起來.',
        spaceMissingQuotes: '包含關鍵字的空間必須用引號括起來.',
        unexpectedString: '在關鍵位置發現意外字符串.',
    },
    noTrailingOrLeadingComma: '不允許在數組和對象中拖尾或引導逗號.',
};
