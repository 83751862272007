import React, { lazy, Suspense } from 'react';
import { Switch, Route, RouteProps } from 'react-router-dom';
import PATH, {
    APP_PREFIX,
    IFTTT_PREFIX,
    FULLPAGE_PREFIX,
    STORE_PREFIX,
} from './pathConstants';
import Authentication from './Authentication';

export const WaitingComponent = (Component: any) => (props: any) =>
    (
        <Suspense fallback={<div>Module loading....</div>}>
            <Component {...props} />
        </Suspense>
    );

const EmptyPage = () => (
    <>Opps... Something went wrong. Please check your url path again.</>
);

/*
  Layout
*/
const App = lazy(() => import('container/App/App/App'));
const FullPage = lazy(() => import('container/App/FullPage/FullPage'));
const IFTTT = lazy(() => import('container/App/IFTTT/IFTTT'));
const Store = lazy(() => import('container/App/Store/Store'));

const routes = () => (
    <Authentication>
        <Switch>
            <Route path={APP_PREFIX} render={WaitingComponent(App)} />
            <Route path={IFTTT_PREFIX} render={WaitingComponent(IFTTT)} />
            <Route path={STORE_PREFIX} render={WaitingComponent(Store)} />
            <Route path={FULLPAGE_PREFIX} render={WaitingComponent(FullPage)} />
            <Route render={EmptyPage} />
        </Switch>
    </Authentication>
);

/*
  APP Routes
*/
const DeviceConfig = lazy(() => import('container/ProductBuild/ProductBuild'));
const DeviceType = lazy(() => import('container/DeviceType/DeviceType'));
const DeviceModel = lazy(() => import('container/DeviceModel/DeviceModel'));
const Explanation = lazy(() => import('container/Explanation/Explanation'));
const DeviceMgmt = lazy(() => import('container/DeviceMgmt/DeviceMgmt'));
const StaffingMgmt = lazy(() => import('container/StaffingMgmt/StaffingMgmt'));
const OverView = lazy(() => import('container/OverView/OverView'));
const RoleMgmt = lazy(() => import('container/RoleMgmt/RoleMgmtN'));
const OrganizationMgmt = lazy(
    () => import('container/OrganizationMgmt/OrganizationMgmt')
);
const UserMgmt = lazy(() => import('container/UserMgmt/UserMgmt'));
const DeviceMap = lazy(() => import('container/DeviceMap/DeviceMap'));
const FirmwareMgmt = lazy(() => import('container/FirmwareMgmt/FirmwareMgmt'));
const AirOtaMgmt = lazy(() => import('container/AirOtaMgmt/AirOtaMgmt'));
const TriggerTemplate = lazy(
    () => import('container/TriggerTemplate/TriggerTemplate')
);
const TriggerAction = lazy(
    () => import('container/TriggerAction/TriggerAction')
);
const TriggerRule = lazy(() => import('container/TriggerRule/TriggerRule'));
const GroupsDeviceMgmt = lazy(
    () => import('container/GroupsDeviceMgmt/GroupsDeviceMgmt')
);
const MobileDeviceMgmt = lazy(
    () => import('container/MobileDeviceMgmt/MobileDeviceMgmt')
);
const ProductAttrMgmt = lazy(() => import('container/ProductAttr/ProductAttr'));
const ProductMgmt = lazy(() => import('container/ProductMgmt/ProductMgmt'));
const FOTACampaignMgmt = lazy(
    () => import('container/FOTACampaignMgmt/FOTACampaignMgmt')
);
const TenantServiceMgmt = lazy(
    () => import('container/TenantServiceMgmt/TenantServiceMgmt')
);
const SitePermissionMgmt = lazy(
    () => import('container/SitePermission/SitePermissionMgmt')
);
const MobileDeviceMgmtAdmin = lazy(
    () => import('container/MobileDeviceMgmtAdmin/MobileDeviceMgmtAdmin')
);
const appRoutes: RouteProps[] = [
    { path: PATH.OVERVIEW, render: WaitingComponent(OverView) },
    { path: PATH.PERMISSION_MGM, render: WaitingComponent(RoleMgmt) },
    { path: PATH.ACCOUNT_MGM, render: WaitingComponent(UserMgmt) },
    { path: PATH.TENANT_MGM, render: WaitingComponent(OrganizationMgmt) },
    { path: PATH.DEVICE_TYPE_MGM, render: WaitingComponent(DeviceType) },
    { path: PATH.DEVICE_MODEL_MGM, render: WaitingComponent(DeviceModel) },
    { path: PATH.DEVICE_CONFIG, render: WaitingComponent(DeviceConfig) },
    { path: PATH.DEVICE_MGM, render: WaitingComponent(DeviceMgmt) },
    {
        path: PATH.TENANT_SERVICE_MGM,
        render: WaitingComponent(TenantServiceMgmt),
    },
    { path: PATH.DEVICE_MAP, render: WaitingComponent(DeviceMap) },
    { path: PATH.EXPLANATION, render: WaitingComponent(Explanation) },
    { path: PATH.STAFFING_MGM, render: WaitingComponent(StaffingMgmt) },
    { path: PATH.FIRMWARE_MGM, render: WaitingComponent(FirmwareMgmt) },
    { path: PATH.AIR_OTA_MGM, render: WaitingComponent(AirOtaMgmt) },
    { path: PATH.TRIGGER_TEMPLATE, render: WaitingComponent(TriggerTemplate) },
    { path: PATH.TRIGGER_ACTION, render: WaitingComponent(TriggerAction) },
    { path: PATH.TRIGGER_RULE, render: WaitingComponent(TriggerRule) },
    {
        path: PATH.FOTA_CAMPAIGN_MGM,
        render: WaitingComponent(FOTACampaignMgmt),
    },
    {
        path: PATH.PRODUCT_ATTRIBUTES,
        render: WaitingComponent(ProductAttrMgmt),
    },
    {
        path: PATH.PRODUCT_MGM,
        render: WaitingComponent(ProductMgmt),
    },
    {
        path: PATH.GROUPS_DEVICE_MGM,
        render: WaitingComponent(GroupsDeviceMgmt),
    },
    {
        path: PATH.MOBILE_DEVICE_MGM,
        render: WaitingComponent(MobileDeviceMgmt),
    },
    {
        path: PATH.SITE_PERMISSION_MGM,
        render: WaitingComponent(SitePermissionMgmt),
    },
    {
        path: PATH.MOBILE_DEVICE_MGM_ADMIN,
        render: WaitingComponent(MobileDeviceMgmtAdmin),
    },
    { render: WaitingComponent(OverView) },
];

export const AppRoutes = () => (
    <Switch>
        {appRoutes.map((config) => (
            <Route key={`route_${config.path}`} exact {...config} />
        ))}
    </Switch>
);

/*
  IFTTT Routes
*/
const IftttHome = lazy(() => import('container/IftttHome/IftttHome'));

const iftttRoutes: RouteProps[] = [
    { path: PATH.IFTTT_HOME, render: WaitingComponent(IftttHome) },
];

export const IftttRoutes = () => (
    <Switch>
        {iftttRoutes.map((config) => (
            <Route key={`route_${config.path}`} exact {...config} />
        ))}
    </Switch>
);

/*
  Market place Routes
*/
const storeHome = lazy(() => import('container/StoreHome/StoreHome'));
const shoppingCart = lazy(() => import('container/ShoppingCart/ShoppingCart'));
const programDetail = lazy(
    () => import('container/ProgramDetail/ProgramDetail')
);
const storeBinding = lazy(() => import('container/StoreBinding/StoreBinding'));
const bindingDetail = lazy(
    () => import('container/StoreBinding/BindingDetail')
);
const bindingRecord = lazy(
    () => import('container/StoreBinding/BindingRecord')
);
const storeProgram = lazy(() => import('container/StoreProgram/StoreProgram'));

const storeRoutes: RouteProps[] = [
    { path: PATH.STORE_HOME, render: WaitingComponent(storeHome) },
    { path: PATH.SHOPPING_CART, render: WaitingComponent(shoppingCart) },
    { path: PATH.PROGRAM_DETAIL, render: WaitingComponent(programDetail) },
    { path: PATH.BINDING_DETAIL, render: WaitingComponent(bindingDetail) },
    { path: PATH.BINDING_RECORD, render: WaitingComponent(bindingRecord) },
    { path: PATH.STORE_BINDING, render: WaitingComponent(storeBinding) },
    { path: PATH.PROGRAM, render: WaitingComponent(storeProgram) },
];

export const StoreRoutes = () => (
    <Switch>
        {storeRoutes.map((config) => (
            <Route key={`route_${config.path}`} exact {...config} />
        ))}
    </Switch>
);

/*
  FULLPAGE Routes
*/
const Login = lazy(() => import('container/Login/Login'));

const fullpageRoutes: RouteProps[] = [
    { path: PATH.LOGIN, render: WaitingComponent(Login) },
    { render: EmptyPage },
];

export const FullpageRoutes = () => (
    <Switch>
        {fullpageRoutes.map((config) => (
            <Route key={`route_${config.path}`} exact {...config} />
        ))}
    </Switch>
);

export default routes;
