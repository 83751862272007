import React, { useContext } from 'react';
import { ThemeProvider } from 'styled-components';
import { IntlProvider } from 'react-intl';
import { ConfigProvider } from 'antd';
import { mergeDeepRight } from 'ramda';
// eslint-disable-next-line import/no-extraneous-dependencies
import Pagination from 'rc-pagination/lib/locale/zh_TW';
import { GlobalContext } from 'store/appStore';
import Router from './router/router';
import GlobalStyles from './GlobalStyles';
import theme from './theme';
import { intl, getMessage, getAntdMessage } from './assets/i18n';
import './App.sass';

const App: React.FC = () => {
    const { lang } = useContext(GlobalContext);
    return (
        <ConfigProvider
            locale={{
                ...getAntdMessage(lang),
                Pagination: mergeDeepRight(Pagination, {
                    items_per_page: intl().formatMessage({
                        id: 'COMMON.PERPAGE',
                    }),
                }),
            }}
        >
            <IntlProvider
                locale={lang}
                key={lang}
                messages={getMessage(lang)}
                defaultLocale="tw"
            >
                <ThemeProvider theme={theme}>
                    <GlobalStyles />
                    <Router />
                </ThemeProvider>
            </IntlProvider>
        </ConfigProvider>
    );
};

export default App;
